import styles from './TrialToggle.module.css';

const TrialToggle = ({ toggled, onToggle, ...trial }) => {
  return (
    <input
      className={styles.trialToggle}
      type='checkbox'
      checked={toggled || false}
      onChange={(event) => onToggle(trial, event.target.checked)}
    />
  );
};

export default TrialToggle;
