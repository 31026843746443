import Cookies from 'js-cookie';

interface GetHostOptions {
  devUseExplicitHost?: boolean;
}

// TODO(evan): Yuck, ideally the host should live in an environment variable,
// but there isn't a good way to share .env files across CRA apps.
export function getHost(options?: GetHostOptions): string {
  if (process.env.NODE_ENV === 'production') {
    return 'https://api.xyla.com';
  } else {
    if (options?.devUseExplicitHost) {
      return 'http://127.0.0.1:8000';
    } else {
      return '';
    }
  }
}

export function getAuthorizationHeader() {
  if (process.env.XYLA_API_KEY) {
    return {
      Authorization: `Token ${process.env.XYLA_API_KEY}`,
    };
  } else {
    return {};
  }
}

export function fetch(
  path: string,
  init?: RequestInit,
  includeCSRFToken: boolean = true,
  options?: GetHostOptions
): Promise<Response> {
  const host = getHost(options);
  const url = `${host}${path}`;

  let headers = {
    ...init?.headers,
  };

  if (includeCSRFToken) {
    const csrftoken = Cookies.get('csrftoken');
    headers = {
      'X-CSRFToken': csrftoken,
      ...getAuthorizationHeader(),
      ...headers,
    };
  }

  return window.fetch(url, {
    ...init,
    headers: headers,
    credentials: 'include',
  });
}
