import React from 'react';
import Highlighter from 'react-highlight-words';

export default function Hightlight({ terms, text }) {
  if (!terms || !text || terms.length === 0) {
    return <>{text}</>;
  } else {
    return (
      <Highlighter
        searchWords={terms}
        textToHighlight={text}
        highlightTag='strong'
      />
    );
  }
}
