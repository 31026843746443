import React from 'react';

import img_xyla from '../../assets/xyla-colour-logo_newx_100height.png';
import styles from './NavBar.module.css';

const NavBar = (props) => {
  return (
    <div
      className={
        props.modalVisible
          ? `${styles.navbar} ${styles.transparent}`
          : props.loaderVisible
          ? `${styles.navbar} ${styles.hidden}`
          : `${styles.navbar}`
      }
    >
      <div></div>
      <div
        className={
          props.modalVisible
            ? `${styles.logo_container_demo} ${styles.transparent}`
            : `${styles.logo_container_demo}`
        }
      >
        <img src={img_xyla} alt='logo' />
      </div>
      <div></div>
    </div>
  );
};

export default NavBar;
