import React, { useEffect, useState } from 'react';

import FilterBar from './FilterBar';
import RelatedTrials from './RelatedTrials';
import Section from './Section';
import TableOfContents from './TableOfContents';
import styles from './Bibliography.module.css';

const sectionsToResultsMessage = (sections) => {
  let trials = new Set();
  for (let section of sections) {
    for (let category of section.categories) {
      for (let trial of category.trials) {
        trials.add(trial.pmid);
      }
    }
  }

  if (trials.size === 1) {
    return 'Found 1 matching clinical trial.';
  } else {
    return `Found ${trials.size} matching clinical trials.`;
  }
};

// TODO(evan): Rename in_article_version.
const Bibliography = ({
  sections,
  related,
  in_article_version,
  onTrialToggle,
  enableToggle = false,
}) => {
  const [displayedSections, setDisplayedSections] = useState(sections);

  useEffect(() => {
    setDisplayedSections(sections);
  }, [sections]);
  const firstSection = sections && sections[0];
  const highlighted = firstSection?.highlighted || {};

  const [sortBy, setSortBy] = useState({
    variable: 'dt_published',
    ascending: false,
  });

  return (
    <div className={styles.bibliography}>
      {displayedSections !== undefined && !in_article_version && (
        <div className={styles.bibliographyResultsMessage}>
          {displayedSections.length === 0 && 'No matching clinical trials.'}
          {displayedSections.length > 0 &&
            sectionsToResultsMessage(displayedSections)}
        </div>
      )}
      {sections !== undefined &&
        displayedSections !== undefined &&
        !in_article_version && (
          <FilterBar
            sections={sections}
            onFilter={setDisplayedSections}
            numTopTrials={10}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        )}
      {in_article_version && (
        <div className={styles.bibliographyInArticleVersionHeader}></div>
      )}
      {displayedSections !== undefined &&
        displayedSections.length > 0 &&
        displayedSections[0].categories.length > 0 &&
        displayedSections[0].categories[0].name.length > 0 && (
          <TableOfContents sections={displayedSections} />
        )}
      {displayedSections !== undefined &&
        displayedSections.map((section) => (
          <Section
            key={section.name.toLowerCase().replace(' ', '-')}
            onTrialToggle={onTrialToggle}
            enableToggle={enableToggle}
            sortBy={sortBy}
            {...section}
          />
        ))}
      {related !== undefined && related.length > 0 && !in_article_version && (
        <RelatedTrials
          trials={related}
          highlighted={highlighted}
          onTrialToggle={onTrialToggle}
          enableToggle={enableToggle}
        />
      )}
    </div>
  );
};

export default Bibliography;
