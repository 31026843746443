import React from 'react';

import styles from './TrialTags.module.css';

const getCitationsTagText = (citedByNum) => {
  if (citedByNum == null) {
    return 'Citations Unknown';
  }
  return `Known Citations: ${citedByNum}+`;
};

const getStudyTypeTagText = (study_type) => {
  return `Design: ${study_type}`;
};

const getPmidTagText = (pmid) => {
  return `PMID: ${pmid}`;
};

const getScoreTagTextAndStyle = (evidenceScore) => {
  const textAndStyle = (scoreTagText, scoreTagNumber, scoreTagStyle) => ({
    scoreTagText,
    scoreTagNumber,
    scoreTagStyle,
  });
  const text = `Xyla Evidence Score: `;
  const number = evidenceScore;
  if (evidenceScore <= 4) {
    return textAndStyle(text, number, styles.tagLowQualityTrial);
  } else if (evidenceScore <= 7) {
    return textAndStyle(text, number, styles.tagMidTierTrial);
  } else {
    return textAndStyle(text, number, styles.tagTopTrial);
  }
};

const TrialTags = (props) => {
  const citationsTagText = getCitationsTagText(props.cited_by_num);
  const studyTypeTagText = getStudyTypeTagText(props.study_design_long);
  const { scoreTagText, scoreTagNumber } = getScoreTagTextAndStyle(
    props.evidence_score
  );
  const pmidTagText = getPmidTagText(props.pmid);
  return (
    <div className={styles.trialTags}>
      {props.evidence_score !== undefined && (
        <div className={styles.tagScore}>
          {scoreTagText}
          {scoreTagNumber}
        </div>
      )}
      {props.cited_by_num !== undefined && (
        <div className={styles.tagOther}>{citationsTagText}</div>
      )}
      {props.study_design_long && (
        <div className={styles.tagOther}>{studyTypeTagText}</div>
      )}
      {props.pmid && <div className={styles.tagOther}>{pmidTagText}</div>}
    </div>
  );
};

export default TrialTags;
