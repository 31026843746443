import React, { useEffect, useState } from 'react';

import styles from './FilterBar.module.css';

const filterTrialsById = (sections, trialIds) => {
  let filteredSections = [];
  for (const section of sections) {
    const filteredCategories = [];
    for (const category of section.categories) {
      const filteredTrials = [];
      for (const trial of category.trials) {
        if (trialIds.has(trial.id)) {
          filteredTrials.push(trial);
        }
      }
      if (filteredTrials.length > 0) {
        const filteredCategory = Object.assign({}, category, {
          trials: filteredTrials,
        });
        filteredCategories.push(filteredCategory);
      }
    }
    if (filteredCategories.length > 0) {
      const filteredSection = Object.assign({}, section, {
        categories: filteredCategories,
      });
      filteredSections.push(filteredSection);
    }
  }

  return filteredSections;
};

const filterTopTrials = (sections, numTopTrials, byField) => {
  let trials = [];
  // Some trials appear in multiple sections.
  // Make sure to not add trial data twice.
  let seen = new Set();
  for (const section of sections) {
    for (const category of section.categories) {
      for (const trial of category.trials) {
        if (!seen.has(trial.id)) {
          trials.push({ id: trial.id, key: trial[byField] });
          seen.add(trial.id);
        }
      }
    }
  }

  trials.sort((a, b) => b.key - a.key);
  let keep = new Set(trials.slice(0, numTopTrials).map((trial) => trial.id));

  return filterTrialsById(sections, keep);
};

const filterTopTrialsByEvidenceScore = (sections, numTopTrials) => {
  return filterTopTrials(sections, numTopTrials, 'evidence_score');
};

const filterTopTrialsByCitations = (sections, numTopTrials) => {
  return filterTopTrials(sections, numTopTrials, 'cited_by_num');
};

const getTrialPublishYear = (trial) => {
  const publicationDate = trial.publication_date;
  if (publicationDate === undefined) {
    return null;
  }

  const yearString = publicationDate.split('-')[0];
  try {
    return parseInt(yearString);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const filterTrialsByPublishYear = (
  sections,
  minPublishYear,
  maxPublishYear
) => {
  let trialIds = new Set();
  for (const section of sections) {
    for (const category of section.categories) {
      for (const trial of category.trials) {
        const trialPublishYear = getTrialPublishYear(trial);
        if (trialPublishYear === undefined) {
          continue;
        }
        const afterMinYear =
          minPublishYear === undefined || trialPublishYear >= minPublishYear;
        const beforeMaxYear =
          maxPublishYear === undefined || trialPublishYear <= maxPublishYear;
        if (afterMinYear && beforeMaxYear) {
          trialIds.add(trial.id);
        }
      }
    }
  }
  return filterTrialsById(sections, trialIds);
};

const filterBarRowClassName = (active) => {
  let className = styles.filterBarRow;
  if (!active) {
    className = `${className} ${styles.filterBarRowInactive}`;
  }
  return className;
};

const FilterBar = ({ sections, onFilter, numTopTrials, setSortBy }) => {
  const [
    useFilterTopTrialsByEvidenceScore,
    setUseFilterTopTrialsByEvidenceScore,
  ] = useState(false);
  const [useFilterTopTrialsByCitations, setUseFilterTopTrialsByCitations] =
    useState(false);
  const [useFilterPublishYear, setUseFilterPublishYear] = useState(false);
  const [minPublishYear, setMinPublishYear] = useState(undefined);
  const [maxPublishYear, setMaxPublishYear] = useState(undefined);

  useEffect(() => {
    setUseFilterTopTrialsByEvidenceScore(false);
    setUseFilterTopTrialsByCitations(false);
    setUseFilterPublishYear(false);
    setMinPublishYear(undefined);
    setMaxPublishYear(undefined);
  }, [sections]);

  const applyFilters = ({
    sections_ = sections,
    useFilterTopTrialsByEvidenceScore_ = useFilterTopTrialsByEvidenceScore,
    useFilterTopTrialsByCitations_ = useFilterTopTrialsByCitations,
    useFilterPublishYear_ = useFilterPublishYear,
    minPublishYear_ = minPublishYear,
    maxPublishYear_ = maxPublishYear,
  }) => {
    let filteredSections = sections_;
    if (useFilterTopTrialsByEvidenceScore_) {
      filteredSections = filterTopTrialsByEvidenceScore(
        filteredSections,
        numTopTrials
      );
    }
    if (useFilterTopTrialsByCitations_) {
      filteredSections = filterTopTrialsByCitations(
        filteredSections,
        numTopTrials
      );
    }
    if (useFilterPublishYear_) {
      filteredSections = filterTrialsByPublishYear(
        filteredSections,
        minPublishYear_,
        maxPublishYear_
      );
    }
    onFilter(filteredSections);
  };

  // TODO(evan): Getting to be a lot of duplication here. How to break up?
  return (
    <div className={styles.filterBar}>
      <div>
        <label>Sort by:&nbsp;</label>
        <select
          onChange={(event) => {
            const variable = event.target.value;
            const ascending = false;
            setSortBy({ variable: variable, ascending: ascending });
          }}
        >
          <option value='dt_published'>Date published</option>
          <option value='evidence_score'>Xyla evidence score</option>
          <option value='cited_by_num'>Number of citations</option>
        </select>
      </div>
    </div>
  );
};

export default FilterBar;
