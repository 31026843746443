import React from 'react';

import styles from './TableOfContents.module.css';

const TableOfContents = (props) => {
  return (
    <div className={styles.tableOfContents}>
      <h3>Contents</h3>
      <ol className={styles.list}>
        {props.sections[0].name.length > 0 &&
          props.sections.map((section) => (
            <li>
              <div>
                <a href={section.name.replace(' ', '-')}>
                  {section.name || 'Section'}
                </a>
              </div>
              <ol className={styles.list}>
                {section.categories.map((category, index) => (
                  <li key={`${index}-${category.name.replace(' ', '-')}`}>
                    <a href={`#${category.name.replace(' ', '-')}`}>
                      {category.name}
                    </a>
                  </li>
                ))}
              </ol>
            </li>
          ))}
        {props.sections[0].name.length === 0 &&
          props.sections[0].categories.map((category, index) => (
            <li key={`${index}-${category.name.replace(' ', '-')}`}>
              <a href={`#${category.name.replace(' ', '-')}`}>
                {category.name}
              </a>
            </li>
          ))}
      </ol>
    </div>
  );
};

export default TableOfContents;
