export function dateStringToYMD(dateString) {
  const [year, month, day] = dateString.split('-');
  return [parseInt(year), parseInt(month), parseInt(day)];
}

export function compareDateStrings(a, b) {
  const [ay, am, ad] = dateStringToYMD(a);
  const [by, bm, bd] = dateStringToYMD(b);
  return ay - by || am - bm || ad - bd;
}
