import React from 'react';

import Collapsible from 'react-collapsible';
import { BsChevronDown } from 'react-icons/bs';

import Category from './Category';
import styles from './Section.module.css';

const Section = (props) => {
  const collapsible = !!props.name.trim();
  const categoryElements = props.categories.map((category) => {
    const categoryId = category.name.toLowerCase().replace(' ', '-');
    return (
      <Category
        categoryId={categoryId}
        key={categoryId}
        highlighted={props.highlighted}
        onTrialToggle={props.onTrialToggle}
        enableToggle={props.enableToggle}
        sortBy={props.sortBy}
        {...category}
      />
    );
  });
  return collapsible ? (
    <Collapsible
      open={props.open || collapsible}
      trigger={
        collapsible
          ? [
              <BsChevronDown
                key={props.name.toLowerCase().replace(' ', '-')}
              />,
              props.name,
            ]
          : undefined
      }
      triggerClassName={styles.sectionHeader}
      triggerOpenedClassName={styles.sectionHeader}
      className={styles.section}
      openedClassName={styles.section}
      contentInnerClassName={styles.sectionCategories}
    >
      {categoryElements}
    </Collapsible>
  ) : (
    <div className={styles.section}>{categoryElements}</div>
  );
};

export default Section;
