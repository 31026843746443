import React from 'react';

import TrialAnchor from './TrialAnchor';
import TrialCitation from './TrialCitation';
import TrialTags from './TrialTags';
import TrialToggle from './TrialToggle';
import styles from './Table.module.css';
import Hightlight from './Highlight';

const FIELDS = [
  'size',
  'intervention',
  'interventionB',
  'population',
  'populationB',
];

const sortByFields = (structuredSources) => {
  return structuredSources.sort((a, b) => {
    for (let field of FIELDS) {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      }
    }
    return 0;
  });
};

const dedupeAdjacent = (structuredSources) => {
  let current = {};
  return structuredSources.map((source) => {
    let result = {};
    for (let key of FIELDS) {
      if (source[key] !== current[key]) {
        result[key] = source[key];
        break;
      } else {
        result[key] = '';
      }
    }
    for (let key in source) {
      if (!result.hasOwnProperty(key)) {
        result[key] = source[key];
      }
    }
    current = source;
    return result;
  });
};

const Table = ({ highlighted = {}, enableToggle = false, ...props }) => {
  let structuredSources = dedupeAdjacent(
    sortByFields(props.structured_sources)
  );
  return (
    <table className={styles.table}>
      <colgroup>
        <col className={styles.colSize} />
        <col className={styles.colIntervention} />
        <col className={styles.colComparedTo} />
        <col className={styles.colPopulation} />
        <col className={styles.colResult} />
      </colgroup>
      <thead>
        <tr>
          <td colSpan='5'>
            <div className={styles.studyMeta}>
              {enableToggle && <TrialToggle {...props} />}
              <TrialAnchor linkId={props.tableId} />
              <TrialTags
                evidence_score={props.evidence_score}
                cited_by_num={props.cited_by_num}
                study_design_long={props.study_design_long}
                pmid={props.pmid}
              />
            </div>
          </td>
        </tr>
        <tr className={styles.studyInfo}>
          <th colSpan='5'>
            <TrialCitation {...props} />
          </th>
        </tr>
        <tr className={styles.studyHeadings}>
          <th>Size</th>
          <th>Intervention</th>
          <th>Compared to</th>
          <th>Population</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {structuredSources.map((source, index) => (
          <tr
            key={`${index}-${props.pmid}-${source.result_verb.replace(
              ' ',
              '-'
            )}-${source.variable.replace(' ', '-')}`}
          >
            <td>{source.size === -1 ? '?' : source.size}</td>
            <td>
              <Hightlight
                terms={highlighted.intervention}
                text={source.intervention}
              />
            </td>
            <td>
              <Hightlight
                terms={highlighted.intervention}
                text={source.interventionB}
              />
            </td>
            <td>
              <Hightlight
                terms={highlighted.population}
                text={source.population}
              />
            </td>
            <td>
              {source.result_verb}{' '}
              <Hightlight terms={highlighted.variable} text={source.variable} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
