import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AiOutlineLink } from 'react-icons/ai';

import styles from './TrialAnchor.module.css';

const TrialAnchor = ({ linkId }) => {
  const { hash } = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    if (hash === `#${linkId}` && ref.current !== null) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  return (
    <div id={linkId} ref={ref} className={styles.trialAnchor}>
      <a
        href={`#${linkId}`}
        onClick={() => {
          // TODO(evan): Ideally, clicking this would place the finding
          // at the top of the page, but it's off a bit.
          // eslint-disable-next-line compat/compat
          navigator.clipboard.writeText(window.location.href);
        }}
      >
        <AiOutlineLink title='link' size='1.5em' />
      </a>
    </div>
  );
};

export default TrialAnchor;
