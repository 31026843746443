import React from 'react';
import Hightlight from './Highlight';

import styles from './TrialCitation.module.css';

const formatDateString = (dateString) => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const TrialCitation = ({
  pmid,
  title,
  journal,
  publication_date,
  full_citation,
  highlighted = {},
}) => {
  return (
    <div className={styles.citation}>
      <div className={styles.dateJournal}>
        {formatDateString(publication_date)}: <i>{journal}</i>
      </div>
      <div className={styles.authors}>{full_citation}</div>
      <div className={styles.title}>
        <a
          href={`https://pubmed.ncbi.nlm.nih.gov/${pmid}`}
          target='_blank'
          rel='noreferrer'
        >
          <Hightlight terms={highlighted.title} text={title} />
        </a>
      </div>
    </div>
  );
};

export default TrialCitation;
