import React from 'react';

import Table from './Table';
import { compareDateStrings } from './dates';

import styles from './Category.module.css';

function comparePublicationDateStrings(a, b) {
  return compareDateStrings(a.publication_date, b.publication_date);
}

function compareEvidenceScores(a, b) {
  return a.evidence_score - b.evidence_score;
}

function compareCitations(a, b) {
  return a.cited_by_num - b.cited_by_num;
}

const Category = (props) => {
  // TODO(evan): This should use a react hook.
  let variableToSortByFunc = {
    dt_published: comparePublicationDateStrings,
    evidence_score: compareEvidenceScores,
    cited_by_num: compareCitations,
  };
  let sortByFunc_ = variableToSortByFunc[props.sortBy['variable']];
  let sortByFunc;
  if (props.sortBy['ascending']) {
    sortByFunc = sortByFunc_;
  } else {
    sortByFunc = (a, b) => -sortByFunc_(a, b);
  }
  let trials = [...props.trials];
  trials.sort(sortByFunc);
  return (
    <div id={props.name.replace(' ', '-')} className={styles.category}>
      <div className={styles.categoryHeader}>{props.name}</div>
      <div className={styles.categoryTables}>
        {trials.map((trial) => (
          <div key={trial.pmid}>
            <Table
              tableId={`${props.categoryId}-${trial.pmid}`}
              enableToggle={props.enableToggle}
              onToggle={props.onTrialToggle}
              highlighted={props.highlighted}
              {...trial}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
