import { useEffect } from 'react';
import { getHost } from '@xyla/api';

import Cookies from 'js-cookie';

const RequiresLogin = ({ path = '/accounts/login', children }) => {
  const hasToken = Cookies.get('csrftoken') !== undefined;
  useEffect(() => {
    if (!hasToken) {
      const host = getHost({ devUseExplicitHost: true });
      const url = `${host}${path}`;

      // TODO(evan): Make so login is not recorded in history once we use
      // react-router to manage all this.
      const nextLocation = `${url}/?next=${window.location.toString()}`;
      window.location.assign(nextLocation);
    }
  }, [hasToken]);

  return hasToken ? children : null;
};

export default RequiresLogin;
