import React, { useState } from 'react';

import { Bibliography, BibliographyQueryBar } from '@xyla/bibliography';
import { RequiresLogin } from '@xyla/auth';
import * as api from '@xyla/api';

import NavBar from '../../components/navbar/NavBar.js';
import styles from './BibliographyPage.module.css';

const ARTICLE_PARAMS = '196'; // New model.

const BibliographyPage = () => {
  const [data, setData] = useState({ sections: undefined });
  const [errorMessage, setErrorMessage] = useState();

  async function onSubmit(fields) {
    setData({ sections: undefined });
    setErrorMessage();

    try {
      const path = `/field_data_query?visible_article_params_ids=${ARTICLE_PARAMS}`;
      const response = await api.fetch(path, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(fields),
      });
      if (!response.ok) {
        throw new Error(
          'Network response was not OK: ' + (await response.text())
        );
      }
      const { related, ...section } = await response.json();
      setData({ sections: [section], related: related });
    } catch (error) {
      setErrorMessage(
        `Uh oh! Something went wrong. Please send this error to xre-support@xyla.com:\n${error}`
      );
    }
  }

  // TODO(evan): Move login redirect behavior to router code.
  // See: https://github.com/xyla-ai/xyla/pull/238/files#r1065114497
  return (
    <RequiresLogin>
      <div className={styles.bibliographyPage}>
        <NavBar />
        <div className={styles.bibliographyContainer}>
          <BibliographyQueryBar onSubmit={onSubmit} />
          {errorMessage && (
            <div className={styles.bibliographyErrorMessage}>
              {errorMessage}
            </div>
          )}
          <Bibliography {...data} />
        </div>
      </div>
    </RequiresLogin>
  );
};

export default BibliographyPage;
