import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import ClipLoader from 'react-spinners/ClipLoader';

import styles from './BibliographyQueryBar.module.css';

const QT_INTERVENTION = 'intervention';
const QT_INTERVENTION_CONDITION = 'intervention_condition';
const QT_VARIABLE = 'variable';
const QT_ALL = 'intervention_condition_variable';
const QT_ALL_POP = 'intervention_population_variable';

const FIELD_PMID = 'pmid';
const FIELD_JOURNAL = 'journal';
const FIELD_INTERVENTION = 'intervention';
const FIELD_CONDITION = 'condition';
const FIELD_VARIABLE = 'variable';
const FIELD_POPULATION = 'population';
const FIELD_AGE = 'age_group';
const FIELD_SEX = 'sex';
const FIELD_OUTCOME = 'result';
const FIELD_SIZE = 'size';
const FIELD_EVIDENCE_SCORE = 'evidence_score';
const FIELD_PUBLICATION_DATE_START = 'publication_date_start';
const FIELD_PUBLICATION_DATE_END = 'publication_date_end';

// TODO(msbarry) remove this once we're done testing elasticsearch so it is configured only from settings.py
const PASS_THROUGH_FIELDS = ['elasticsearch', 'synonyms'];

const NO_CHOICE = { label: 'Any', value: -1 };
const CHOICES_AGE = [
  NO_CHOICE,
  { label: 'Children', value: 1 },
  { label: 'Adults', value: 2 },
  { label: 'Elderly', value: 3 },
];
const CHOICES_SEX = [
  NO_CHOICE,
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
];
const CHOICES_OUTCOME = [
  NO_CHOICE,
  { label: 'Beneficial', value: 1 },
  { label: 'No effect', value: 2 },
  { label: 'Harmful', value: 3 },
  { label: 'Unclear', value: 4 },
  { label: 'Mixed', value: 5 },
];
const CHOICES_BY_FIELD = {
  [FIELD_AGE]: CHOICES_AGE,
  [FIELD_SEX]: CHOICES_SEX,
  [FIELD_OUTCOME]: CHOICES_OUTCOME,
};

const FIELD_TYPE_STRING = 'string';
const FIELD_TYPE_NUMBER = 'number';
const FIELD_TYPE_CATEGORICAL = 'categorical';
const FIELD_TYPE_DATE = 'date';

const LABELS_BY_QUERY_TYPE = {
  [QT_VARIABLE]: 'Variable',
  [QT_INTERVENTION]: 'Intervention',
  [QT_INTERVENTION_CONDITION]: 'Intervention & Condition',
  [QT_ALL]: 'All Fields',
  [QT_ALL_POP]: 'All Fields',
};

const FIX_QUERY_TYPE = true;

const FIELDS_BY_QUERY_TYPE = {
  [QT_INTERVENTION]: [
    { label: 'Intervention', key: FIELD_INTERVENTION, type: FIELD_TYPE_STRING },
    { label: 'Age Group', key: FIELD_AGE, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Sex', key: FIELD_SEX, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Outcome', key: FIELD_OUTCOME, type: FIELD_TYPE_CATEGORICAL },
    {
      label: 'Min. Size',
      key: FIELD_SIZE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 100,
    },
    {
      label: 'Min. Evidence Score',
      key: FIELD_EVIDENCE_SCORE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 10,
    },
  ],
  [QT_INTERVENTION_CONDITION]: [
    { label: 'Intervention', key: FIELD_INTERVENTION, type: FIELD_TYPE_STRING },
    { label: 'Condition', key: FIELD_CONDITION, type: FIELD_TYPE_STRING },
    { label: 'Age Group', key: FIELD_AGE, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Sex', key: FIELD_SEX, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Outcome', key: FIELD_OUTCOME, type: FIELD_TYPE_CATEGORICAL },
    {
      label: 'Min. Size',
      key: FIELD_SIZE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 100,
    },
    {
      label: 'Min. Evidence Score',
      key: FIELD_EVIDENCE_SCORE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 10,
    },
  ],
  [QT_VARIABLE]: [
    { label: 'Variable', key: FIELD_VARIABLE, type: FIELD_TYPE_STRING },
    { label: 'Age Group', key: FIELD_AGE, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Sex', key: FIELD_SEX, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Outcome', key: FIELD_OUTCOME, type: FIELD_TYPE_CATEGORICAL },
    {
      label: 'Min. Size',
      key: FIELD_SIZE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 100,
    },
    {
      label: 'Min. Evidence Score',
      key: FIELD_EVIDENCE_SCORE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 10,
    },
  ],
  [QT_ALL]: [
    { label: 'PMID', key: FIELD_PMID, type: FIELD_TYPE_STRING },
    { label: 'Intervention', key: FIELD_INTERVENTION, type: FIELD_TYPE_STRING },
    { label: 'Condition', key: FIELD_CONDITION, type: FIELD_TYPE_STRING },
    { label: 'Variable', key: FIELD_VARIABLE, type: FIELD_TYPE_STRING },
    { label: 'Age Group', key: FIELD_AGE, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Sex', key: FIELD_SEX, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Outcome', key: FIELD_OUTCOME, type: FIELD_TYPE_CATEGORICAL },
    {
      label: 'Min. Size',
      key: FIELD_SIZE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 200,
    },
    {
      label: 'Min. Evidence Score',
      key: FIELD_EVIDENCE_SCORE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 10,
    },
  ],
  [QT_ALL_POP]: [
    { label: 'Intervention', key: FIELD_INTERVENTION, type: FIELD_TYPE_STRING },
    { label: 'Population', key: FIELD_POPULATION, type: FIELD_TYPE_STRING },
    { label: 'Variable', key: FIELD_VARIABLE, type: FIELD_TYPE_STRING },
    { label: 'Age Group', key: FIELD_AGE, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Sex', key: FIELD_SEX, type: FIELD_TYPE_CATEGORICAL },
    { label: 'Outcome', key: FIELD_OUTCOME, type: FIELD_TYPE_CATEGORICAL },
    { label: 'PMID', key: FIELD_PMID, type: FIELD_TYPE_STRING },
    { label: 'Journal', key: FIELD_JOURNAL, type: FIELD_TYPE_STRING },
    {
      label: 'Min. Size',
      key: FIELD_SIZE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 200,
    },
    {
      label: 'Min. Evidence Score',
      key: FIELD_EVIDENCE_SCORE,
      type: FIELD_TYPE_NUMBER,
      min: 0,
      max: 10,
    },
    {
      label: 'Publication Date',
      key: [FIELD_PUBLICATION_DATE_START, FIELD_PUBLICATION_DATE_END],
      type: FIELD_TYPE_DATE,
    },
  ],
};

const BibliographyQueryBar = ({ onSubmit }) => {
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [queryType, setQueryType] = useState(QT_ALL_POP);
  const [loading, setLoading] = useState(false);
  const fieldHooks = {
    [FIELD_PMID]: useState(queryParams.get(FIELD_PMID) || ''),
    [FIELD_JOURNAL]: useState(queryParams.get(FIELD_JOURNAL) || ''),
    [FIELD_INTERVENTION]: useState(queryParams.get(FIELD_INTERVENTION) || ''),
    [FIELD_CONDITION]: useState(queryParams.get(FIELD_CONDITION) || ''),
    [FIELD_POPULATION]: useState(queryParams.get(FIELD_POPULATION) || ''),
    [FIELD_VARIABLE]: useState(queryParams.get(FIELD_VARIABLE) || ''),
    [FIELD_AGE]: useState(queryParams.get(FIELD_AGE) || NO_CHOICE.value),
    [FIELD_SEX]: useState(queryParams.get(FIELD_SEX) || NO_CHOICE.value),
    [FIELD_OUTCOME]: useState(
      queryParams.get(FIELD_OUTCOME) || NO_CHOICE.value
    ),
    [FIELD_SIZE]: useState(queryParams.get(FIELD_SIZE) || 0),
    [FIELD_EVIDENCE_SCORE]: useState(
      queryParams.get(FIELD_EVIDENCE_SCORE) || 0
    ),
    [FIELD_PUBLICATION_DATE_START]: useState(
      queryParams.get(FIELD_PUBLICATION_DATE_START) || ''
    ),
    [FIELD_PUBLICATION_DATE_END]: useState(
      queryParams.get(FIELD_PUBLICATION_DATE_END) || ''
    ),
  };

  const submit = ({ includeHashInHistory = false } = {}) => {
    let params = {
      query_type: queryType,
    };
    for (const field of PASS_THROUGH_FIELDS) {
      const fieldValue = queryParams.get(field);
      if (fieldValue) {
        params[field] = fieldValue;
      }
    }
    for (let field of FIELDS_BY_QUERY_TYPE[queryType]) {
      if (field.type === FIELD_TYPE_DATE) {
        const fieldValueStart = fieldHooks[field.key[0]][0];
        const fieldValueEnd = fieldHooks[field.key[1]][0];
        if (fieldValueStart) {
          params[field.key[0]] = fieldValueStart;
        }
        if (fieldValueEnd) {
          params[field.key[1]] = fieldValueEnd;
        }
        continue;
      }
      const fieldValue = fieldHooks[field.key][0];
      if (
        field.type === FIELD_TYPE_CATEGORICAL &&
        parseInt(fieldValue) === NO_CHOICE.value
      ) {
        continue;
      }
      params[field.key] = fieldValue;
    }

    navigate({
      pathname,
      search: new URLSearchParams(params).toString(),
      hash: includeHashInHistory ? hash : undefined,
    });

    setLoading(true);
    onSubmit(params).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (Array.from(queryParams.values()).length > 0) {
      submit({ includeHashInHistory: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      className={styles.queryBar}
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <div className={styles.queryInputs}>
        {!FIX_QUERY_TYPE && (
          <div className={styles.queryTypeWrapper}>
            <select
              onChange={(event) => setQueryType(event.target.value)}
              value={queryType}
              className={styles.queryType}
            >
              {Object.entries(LABELS_BY_QUERY_TYPE).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className={styles.querySubfields}>
          {FIELDS_BY_QUERY_TYPE[queryType].map((field) => {
            if (field.type === FIELD_TYPE_STRING) {
              return (
                <div key={field.key} className={styles.querySubfield}>
                  <label>{field.label}</label>
                  <input
                    type='text'
                    value={fieldHooks[field.key][0]}
                    onChange={(event) =>
                      fieldHooks[field.key][1](event.target.value)
                    }
                  />
                </div>
              );
            } else if (field.type === FIELD_TYPE_NUMBER) {
              return (
                <div key={field.key} className={styles.querySubfield}>
                  <label>{field.label}</label>
                  <input
                    type='range'
                    min={field.min}
                    max={field.max}
                    value={fieldHooks[field.key][0]}
                    onChange={(event) =>
                      fieldHooks[field.key][1](event.target.value)
                    }
                  />
                  <output>{fieldHooks[field.key][0]}</output>
                </div>
              );
            } else if (field.type === FIELD_TYPE_CATEGORICAL) {
              return (
                <div key={field.key} className={styles.querySubfield}>
                  <label>{field.label}</label>
                  <select
                    onChange={(event) =>
                      fieldHooks[field.key][1](event.target.value)
                    }
                    value={fieldHooks[field.key][0]}
                    className={styles.queryType}
                  >
                    {CHOICES_BY_FIELD[field.key].map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              );
            } else if (field.type === FIELD_TYPE_DATE) {
              return (
                <div key={field.key} className={styles.querySubfield}>
                  <label>{field.label}</label>
                  <input
                    type='date'
                    value={fieldHooks[field.key[0]][0]}
                    onChange={(event) =>
                      fieldHooks[field.key[0]][1](event.target.value)
                    }
                  />
                  <input
                    type='date'
                    value={fieldHooks[field.key[1]][0]}
                    onChange={(event) =>
                      fieldHooks[field.key[1]][1](event.target.value)
                    }
                  />
                </div>
              );
            } else {
              throw new Error(`Unknown field type: ${field.type}`);
            }
          })}
        </div>
      </div>
      <div className={styles.querySubmit}>
        <input type='submit' value='Search Bibliography' />
        <ClipLoader loading={loading} size={75} />
      </div>
    </form>
  );
};

export default BibliographyQueryBar;
