import React, { useState } from 'react';

import TrialAnchor from './TrialAnchor';
import TrialCitation from './TrialCitation';
import TrialTags from './TrialTags';
import { compareDateStrings } from './dates';
import styles from './RelatedTrials.module.css';
import TrialToggle from './TrialToggle';

function comparePublicationDateStrings(a, b) {
  return compareDateStrings(a.publication_date, b.publication_date);
}

function compareJournalScores(a, b) {
  return a.journal_hindex - b.journal_hindex;
}

function filterTopTrialsClassName(active) {
  let className = styles.filterTopTrials;
  if (!active) {
    className = `${className} ${styles.filterTopTrialsInactive}`;
  }
  return className;
}

const RelatedTrials = ({
  trials,
  highlighted,
  onTrialToggle,
  enableToggle = false,
  numTopTrials = 10,
}) => {
  trials = trials.sort(comparePublicationDateStrings).reverse();

  // TODO(evan): Dedupe this logic with FilterBar, and implement more nicely.
  const [showTopTrials, setShowTopTrials] = useState(false);
  const [shownTrials, setShownTrials] = useState(trials);

  React.useEffect(() => {
    setShownTrials(trials);
  }, [trials]);

  return (
    <div className={styles.related}>
      <div className={styles.headerBlock}>
        <div className={styles.header}>Potentially Related Trials</div>
        <div className={filterTopTrialsClassName(showTopTrials)}>
          <input
            type='checkbox'
            checked={showTopTrials}
            onChange={() => {
              const shouldShowTopTrials = !showTopTrials;
              if (shouldShowTopTrials) {
                const topTrials = [...trials]
                  .sort(compareJournalScores)
                  .reverse();
                setShownTrials(topTrials.slice(0, numTopTrials));
              } else {
                setShownTrials(trials);
              }
              setShowTopTrials(shouldShowTopTrials);
            }}
          />
          <label>Only show top {numTopTrials} trials based on journal</label>
        </div>
      </div>
      <div className={styles.citations}>
        {shownTrials &&
          shownTrials.map((trial) => (
            <div key={trial.pmid}>
              <div className={styles.trialMeta}>
                {enableToggle && (
                  <TrialToggle onToggle={onTrialToggle} {...trial} />
                )}
                <TrialAnchor linkId={trial.pmid} />
                <TrialTags {...trial} />
              </div>
              <TrialCitation {...trial} highlighted={highlighted} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default RelatedTrials;
